import {Injectable} from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {map, Observable} from 'rxjs';
import {AnonymousTokenDto} from '@shared/services/api/order-user.service';

@Injectable({
  providedIn: 'root'
})
export class LokalService extends BaseService {
  constructor() {
    super('lokal');
  }

  loginOrRegister(body: Partial<LokalLoginData>) {
    return this.http.post<TokenDto | LokalLoginOrRegistrationResponseDto>(`${this.REST_PATH}/login-or-register`, body);
  }

  getBalance(): Observable<number> {
    return this.http.get(`${this.REST_PATH}/wallet/balance`, {responseType: 'text'}).pipe(
      map(res => {
        console.log(res)
        return parseInt(res);
      })
    );
  }

  checkCard(anonymousTokenDto?: AnonymousTokenDto): Observable<CheckCardInfo> {
    return this.http.post<CheckCardInfo>(`${this.REST_PATH}/check-card`, {...anonymousTokenDto})
  }

  getDiscountForLoggedUser(dto: GetDiscountForLoggedUserDto) {
    return this.http.post(`${this.REST_PATH}/get-discount`, {...dto});
  }

  getDiscountForAnonymousUser(dto: GetDiscountForAnonymousUSerDto) {
    return this.http.post(`${this.REST_PATH}/get-discount-for-anonymous`, {...dto});
  }

  setAnonymousUserCard(dto: SetAnonymousUserCard) {
    return this.http.post(`${this.REST_PATH}/set-anonymous-user-card`, {...dto})
  }
}

export interface LokalLoginData {
  phone: string,
  nextRequestType?: LokalNextRequestType,
  authCode?: string,
  anonymousToken?: string,
}

export enum LokalNextRequestType {
  REGISTRATION = 'REGISTRATION',
  LOGIN = 'LOGIN',
  ANONYMOUS_USER_WITHOUT_CARD = 'ANONYMOUS_USER_WITHOUT_CARD',
}

export interface TokenDto {
  access_token: string;
  expiresIn: number;
}

export interface  LokalLoginOrRegistrationResponseDto {
  nextRequestType: LokalNextRequestType
}

export type SetAnonymousUserCard = AnonymousTokenDto & {
  phone?: string,
  authCode?: string,
}

export interface GetDiscountForLoggedUserDto {
  paidByPoints?: number,
  coupon?: string
}

export interface CheckCardInfo {
  balanceAfter: number,
  balanceBurn: number,
  balanceEarn: number,
  proposeBurnAmount: number,
  discountGross: number | null,
  cardNo: string
}

export type GetDiscountForAnonymousUSerDto = GetDiscountForLoggedUserDto & AnonymousTokenDto;

// Assuming TokenDto and LokalLoginOrRegistrationResponseDto have unique properties:
export function isTokenDto(response: TokenDto | LokalLoginOrRegistrationResponseDto): response is TokenDto {
  return (response as TokenDto).access_token !== undefined;
}

export function isLokalLoginOrRegistrationResponseDto(response: TokenDto | LokalLoginOrRegistrationResponseDto): response is LokalLoginOrRegistrationResponseDto {
  return (response as LokalLoginOrRegistrationResponseDto).nextRequestType !== undefined;
}

